import * as React from 'react'
import { Layout } from '../layout'
import { Grid } from '../../components/grid'
import './partnerDetailPage.scss'
import { LogoWall, LogoProps } from '../../components'
import { getWebpImage } from '../../utilities'

type Props = {
  brandLogos: LogoProps[] | null
  partner: any
}

class PartnerDetailPage extends React.Component<Props> {
  render() {
    const {
      brandLogos,
      partner: { headerBanner, name },
    } = this.props

    return (
      <Layout>
        <Grid className='partner-detail-page'>
          <div className='row'>
            <div className='partner-detail-page__banner'>
              {name === 'PayPal' ? (
                <a
                  href='https://l.clickfrenzy.au/paypal'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    className='partner-detail-page__banner-image'
                    src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                    data-blink-src={getWebpImage(headerBanner)}
                    alt=''
                  />
                </a>
              ) : (
                <img
                  className='partner-detail-page__banner-image'
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                            wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                  data-blink-src={getWebpImage(headerBanner)}
                  alt=''
                />
              )}
            </div>
          </div>
          {brandLogos && <LogoWall logos={brandLogos} />}
        </Grid>
      </Layout>
    )
  }
}

export default PartnerDetailPage
