import React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers'
import { DictionaryValue } from '../dictionary-value'
import { Modal, Button } from 'antd'
import { Link } from 'react-router-dom'
import bind from 'bind-decorator'
import ExclusiveCountdown from '../exclusive-countdown'
import defaultStyles from './default.module.scss'
import homeStyles from './index.module.scss'
import EventType from '../../models/eventType'
import { getWebpImage } from '../../utilities'

type Props = {
  brandImageURL?: string
  imageURL: string
  formattedTitle?: JSX.Element | string
  formattedOffer?: JSX.Element | string
  optional1?: string | JSX.Element
  optional2?: string | JSX.Element
  brandName?: string
  brandURL?: string
  cta: JSX.Element | string
  toggleFavourite?: (e: React.MouseEvent) => void
  isFavourite?: boolean
  id: string
  url: string
  title: string
  showHover?: boolean | undefined
  location?: 'home' | 'default'
  brandSlug?: string
  isExclusive?: boolean
  isGreyedOut?: boolean
  isLoggedIn?: boolean | undefined
  dealStartTime?: number
  dealEndTime?: number
  couponAlt?: JSX.Element | string
  networkTime: number
  isSponsored?: boolean
  eventType?: EventType | null
  userId?: string | undefined
}

type State = {
  isModalOpen: boolean
}

class DealCard extends React.Component<Props, State> {
  readonly state: State = {
    isModalOpen: false,
  }

  @bind
  openModal() {
    this.setState({
      isModalOpen: true,
    })
  }

  @bind
  handleModalCancel() {
    this.setState({
      isModalOpen: false,
    })
  }

  render() {
    const {
      brandImageURL,
      imageURL,
      formattedTitle,
      formattedOffer,
      optional1,
      optional2,
      brandName,
      brandURL,
      cta,
      toggleFavourite,
      isFavourite,
      id,
      url,
      title,
      showHover,
      location,
      brandSlug,
      isExclusive,
      isGreyedOut,
      isLoggedIn,
      couponAlt,
      dealStartTime,
      networkTime,
      isSponsored,
      eventType,
      userId,
    } = this.props

    const styles = location === 'home' ? homeStyles : defaultStyles
    const isExclusiveUpcomingDealCard =
      isExclusive && networkTime && dealStartTime && dealStartTime > networkTime ? true : false
    const isExclusiveDealBanner =
      isExclusive && networkTime && dealStartTime && dealStartTime <= networkTime ? true : false

    if (isExclusiveDealBanner && !isGreyedOut && !isLoggedIn) {
      return (
        <>
          <div
            className={`${styles['deal-card']} ${
              showHover !== false && styles['deal-card__hover-effect']
            } ${brandSlug} ${isSponsored && styles['deal-card__sponsored']}`}
            data-deal-id={id}
          >
            <div
              className={styles['deal-card__image-wrapper']}
              onClick={isExclusiveDealBanner && !isGreyedOut ? this.openModal : undefined}
              style={{ cursor: 'pointer' }}
            >
              {isExclusiveDealBanner && (
                <div
                  className={styles['deal-card__exclusive-deal']}
                  style={isGreyedOut ? { backgroundColor: '#4e4c4d', zIndex: '10' } : {}}
                >
                  CF Exclusive
                </div>
              )}
              {imageURL && (
                <img
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                      wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                  data-blink-src={getWebpImage(imageURL)}
                  alt={title}
                  className={styles['deal-card__image']}
                />
              )}
            </div>
            <a href={brandURL}>
              <div
                className={styles[`deal-card__brand-wrapper` + `${isSponsored ? '__sponsored' : ''}`]}
                style={{ zIndex: 1 }}
              >
                {brandImageURL && (
                  <img
                    data-blink-src={getWebpImage(brandImageURL)}
                    data-blink-quality='better'
                    data-blink-resize='200x'
                    alt={brandName}
                    className={styles['deal-card__brand-image']}
                  />
                )}
              </div>
            </a>
            <div
              className={styles['deal-card__details-wrapper']}
              style={{ cursor: 'pointer' }}
              onClick={isExclusiveDealBanner && !isGreyedOut ? this.openModal : undefined}
            >
              <div className={styles['deal-card__title']}>{formattedTitle}</div>
              <div className={styles['deal-card__offer']}>{formattedOffer}</div>
              <div className={styles['deal-card__optionals-wrapper']}>
                <div className={styles['deal-card__optionals-1']}>{optional1}</div>
                {isExclusive === true ? (
                  <div className={styles['deal-card__optionals-2']}>{couponAlt}</div>
                ) : (
                  <div className={styles['deal-card__optionals-2']}>{optional2}</div>
                )}
              </div>
            </div>
            {!isSponsored && (
              <div className={styles['deal-card__actions-wrapper']}>
                <div className={styles['deal-card__cta-wrapper']}>
                  <div
                    className={`${styles[`deal-card__cta`]} ${styles[`cta--${eventType}`]}`}
                    style={{ cursor: 'pointer' }}
                    onClick={isExclusiveDealBanner && !isGreyedOut ? this.openModal : undefined}
                  >
                    <div className={styles['deal-card__cta-text-wrapper']}>
                      <div className={styles['deal-card__cta-text']}>{cta}</div>
                      <div className={styles['deal-card__cta-icon']}>
                        <svg
                          width={13}
                          height={11}
                          viewBox='0 0 13 11'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          className={styles['deal-card__cta-svg-icon']}
                        >
                          <path
                            d='M7.23758 1.26221L11.1265 5.26221M11.1265 5.26221L7.23758 9.26221M11.1265 5.26221L1.12646 5.26221'
                            stroke='white'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['deal-card__favourite-wrapper']}>
                  <div
                    className={styles['deal-card__favourite-cta']}
                    onClick={toggleFavourite}
                  >
                    <div className={styles['deal-card__favourite-icon']}>
                      <svg
                        width={20}
                        height={18}
                        viewBox='0 0 20 18'
                        fill={isFavourite ? (isGreyedOut ? '#4e4c4d' : '#BD1A8D') : 'none'}
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.70109 2.81694C1.01695 4.50107 1.01695 7.23159 2.70109 8.91573L10.063 16.2777L17.4249 8.91573C19.109 7.23159 19.109 4.50107 17.4249 2.81693C15.7407 1.1328 13.0102 1.1328 11.3261 2.81694L10.063 4.08009L8.79989 2.81694C7.11575 1.1328 4.38523 1.1328 2.70109 2.81694Z'
                          stroke={isGreyedOut ? '#4e4c4d' : '#BD1A8D'}
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Modal
            title={<DictionaryValue token='exclusive.popup.title' />}
            open={this.state.isModalOpen}
            footer={null}
            onCancel={this.handleModalCancel}
          >
            <div className={styles['exclusives-popup__body']}>
              <DictionaryValue token='exclusive.popup.heading' />
              <Link to='/account?login'>
                <Button
                  type='primary'
                  className={styles[`exclusives-popup__login-cta-${eventType}`]}
                >
                  <DictionaryValue token='exclusive.popup.logincta' />
                </Button>
              </Link>
              <DictionaryValue token='exclusive.popup.conjunction' />
              <div>
                <a
                  href={`https://${url}${isLoggedIn && userId ? '/?userId=' + userId : ''}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <DictionaryValue token='exclusive.popup.dealurlcta' />
                </a>
                &nbsp;
                <DictionaryValue token='exclusive.popup.dealurldesc' />
              </div>
            </div>
          </Modal>
        </>
      )
    }

    if (isExclusiveUpcomingDealCard) {
      return (
        <>
          <div
            className={`${styles['deal-card']} ${
              showHover !== false && styles['deal-card__hover-effect']
            } ${brandSlug} ${isSponsored && styles['deal-card__sponsored']}`}
            data-deal-id={id}
            style={{ cursor: 'default' }}
          >
            <div className={styles['deal-card__image-wrapper']}>
              {imageURL && (
                <img
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                      wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                  data-blink-src='https://ucarecdn.com/988f4066-9d10-4280-82cd-0ae3df0d7714/-/format/webp/'
                  alt={title}
                  className={styles['deal-card__image']}
                />
              )}
            </div>
            <div
              className={styles[`deal-card__brand-wrapper` + `${isSponsored ? '__sponsored' : ''}`]}
              style={{ zIndex: 1 }}
            >
              {brandImageURL && (
                <img
                  data-blink-src='https://ucarecdn.com/988f4066-9d10-4280-82cd-0ae3df0d7714/-/format/webp/'
                  data-blink-quality='better'
                  data-blink-resize='200x'
                  alt='Exclusive Click Frenzy Brand'
                  className={styles['deal-card__brand-image']}
                />
              )}
            </div>
            <div
              className={styles['deal-card__details-wrapper']}
              style={isGreyedOut ? { opacity: 0.5 } : {}}
            >
              <div className={styles['deal-card__upcoming-exclusive-title']}>
                <DictionaryValue token='exclusive.upcoming.title' />
              </div>
              <div className={styles['deal-card__upcoming-exclusive-timer-title']}>
                <DictionaryValue token='exclusive.upcoming.timer.title' />
              </div>
            </div>
            {!isSponsored && (
              <div className={styles['deal-card__actions-wrapper']}>
                {dealStartTime ? (
                  <>
                    <div className={styles['deal-card__exclusive-countdown-timer']}>
                      <ExclusiveCountdown to={dealStartTime} />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </>
      )
    }

    return (
      <>
        <div
          className={`${styles['deal-card']} ${showHover !== false && styles['deal-card__hover-effect']} ${brandSlug} ${
            isSponsored && styles['deal-card__sponsored']
          }`}
          data-deal-id={id}
        >
          <a
            href={`https://${url}${isLoggedIn && userId ? '/?userId=' + userId : ''}`}
            target='_blank'
            rel='noreferrer'
          >
            <div className={styles['deal-card__image-wrapper']}>
              {isExclusiveDealBanner && (
                <div
                  className={styles['deal-card__exclusive-deal']}
                  style={isGreyedOut ? { backgroundColor: '#4e4c4d', zIndex: '10' } : {}}
                >
                  {isGreyedOut ? 'Exclusive Expired' : 'CF Exclusive'}
                </div>
              )}
              {imageURL && (
                <img
                  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAABnRSTlMA/
                                    wD/AP83WBt9AAAADElEQVQI12P4//8/AAX+Av7czFnnAAAAAElFTkSuQmCC'
                  data-blink-src={getWebpImage(imageURL)}
                  alt={title}
                  className={styles['deal-card__image']}
                  style={isGreyedOut ? { filter: 'grayscale(1)' } : {}}
                />
              )}
            </div>
          </a>
          <a href={brandURL}>
            <div
              className={styles[`deal-card__brand-wrapper` + `${isSponsored ? '__sponsored' : ''}`]}
              style={{ zIndex: 1 }}
            >
              {brandImageURL && (
                <img
                  data-blink-src={getWebpImage(brandImageURL)}
                  data-blink-quality='better'
                  data-blink-resize='200x'
                  alt={brandName}
                  className={styles['deal-card__brand-image']}
                />
              )}
            </div>
          </a>
          <a
            href={`https://${url}${isLoggedIn && userId ? '/?userId=' + userId : ''}`}
            target='_blank'
            style={{ height: 'inherit', color: '#222222' }}
            rel='noreferrer'
          >
            <div
              className={styles['deal-card__details-wrapper']}
              style={isGreyedOut ? { opacity: 0.5 } : {}}
            >
              <>
                <div className={styles['deal-card__title']}>{formattedTitle}</div>
                <div className={styles['deal-card__offer']}>{formattedOffer}</div>
                <div className={styles['deal-card__optionals-wrapper']}>
                  <div className={styles['deal-card__optionals-1']}>{optional1}</div>
                  {isExclusive === true ? (
                    isLoggedIn === true ? (
                      <div className={styles['deal-card__optionals-2']}>{optional2}</div>
                    ) : (
                      <div className={styles['deal-card__optionals-2']}>{couponAlt}</div>
                    )
                  ) : (
                    <div className={styles['deal-card__optionals-2']}>{optional2}</div>
                  )}
                </div>
              </>
            </div>
          </a>
          {!isSponsored && (
            <div className={styles['deal-card__actions-wrapper']}>
              <>
                <div className={styles['deal-card__cta-wrapper']}>
                  <a
                    href={`https://${url}${isLoggedIn && userId ? '/?userId=' + userId : ''}`}
                    target='_blank'
                    style={{ height: 'inherit' }}
                    rel='noreferrer'
                  >
                    <div
                      className={`${styles[`deal-card__cta`]} ${styles[`cta--${eventType}`]}`}
                      style={isGreyedOut ? { backgroundColor: '#4e4c4d' } : {}}
                    >
                      <div className={styles['deal-card__cta-text-wrapper']}>
                        <div className={styles['deal-card__cta-text']}>{cta}</div>
                        <div className={styles['deal-card__cta-icon']}>
                          <svg
                            width={13}
                            height={11}
                            viewBox='0 0 13 11'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className={styles['deal-card__cta-svg-icon']}
                          >
                            <path
                              d='M7.23758 1.26221L11.1265 5.26221M11.1265 5.26221L7.23758 9.26221M11.1265 5.26221L1.12646 5.26221'
                              stroke='white'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className={styles['deal-card__favourite-wrapper']}>
                  <div
                    className={styles['deal-card__favourite-cta']}
                    onClick={toggleFavourite}
                  >
                    <div className={styles['deal-card__favourite-icon']}>
                      <svg
                        width={20}
                        height={18}
                        viewBox='0 0 20 18'
                        fill={isFavourite ? (isGreyedOut ? '#4e4c4d' : '#BD1A8D') : 'none'}
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M2.70109 2.81694C1.01695 4.50107 1.01695 7.23159 2.70109 8.91573L10.063 16.2777L17.4249 8.91573C19.109 7.23159 19.109 4.50107 17.4249 2.81693C15.7407 1.1328 13.0102 1.1328 11.3261 2.81694L10.063 4.08009L8.79989 2.81694C7.11575 1.1328 4.38523 1.1328 2.70109 2.81694Z'
                          stroke={isGreyedOut ? '#4e4c4d' : '#BD1A8D'}
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState, currentProps: Omit<Props, 'networkTime' | 'userId'>): Props => {
  return {
    ...currentProps,
    networkTime: state.status.networkTime,
    userId: state.account.username,
  }
}

const DealCardWithData = connect(mapStateToProps)(DealCard)

export default DealCardWithData
