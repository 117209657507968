import BrandLogoSize from '../models/brandLogoSize'

export const getResizedImageFromCDN = (url: string, isBitmap: boolean, dimension: number) => {
  if (!url || !url.startsWith('https://ucarecdn.com')) return url
  if (!isBitmap) {
    return getWebpImage(url)
  }
  return `${url}-/resize/${dimension}/`
}

export const brandLogoSize: BrandLogoSize = {
  featured: 120,
  list: 60,
  dealCard: 125,
  filter: 24,
  detail: 98,
  detailModal: 40,
  interstitial: 120,
}

export const getWebpImage = (url: string) => {
  if (
    url &&
    url.startsWith('https://ucarecdn.com') &&
    !['crop', 'resize', 'webp', 'format'].some(key => url.split('/').includes(key))
  ) {
    url = url + '-/format/webp/'
    return url
  }
  return url
}
