import React from 'react'
import { DictionaryValue } from '../dictionary-value'
import makeCountdown from '../../properties/makeCountdown'
import styles from './index.module.scss'
import EventType from '../../models/eventType'
import { getWebpImage } from '../../utilities'

type Props = {
  hours: number
  minutes: number
  seconds: number
  countDownTimerLogo: string
  eventType?: EventType | null
}

class MainCountdownComponent extends React.Component<Props> {
  render() {
    const { hours, minutes, seconds, countDownTimerLogo, eventType } = this.props
    const format = new Intl.NumberFormat('en-AU', { style: 'decimal', minimumIntegerDigits: 2 }).format

    return (
      <div className={`${styles['main-countdown__timer-container']} ${styles[`countdown-${eventType}`]}`}>
        <div className={styles['main-countdown__timer-logo']}>
          <img
            data-blink-src={getWebpImage(countDownTimerLogo)}
            data-blink-quality='better'
            data-blink-resize='200x'
            className={styles['logo-image']}
            alt='main-countdowntimer-logo'
          />
        </div>
        <div className={styles['main-countdown__timer-content']}>
          <div className={`${styles['main-countdown__timer-item']} ${styles[`countdown-${eventType}`]}`}>
            <span className={styles['main-countdown__timer-number']}>{format(hours)}</span>
            <DictionaryValue token='header.countdown.hours' />
          </div>
          <div className={`${styles['main-countdown__timer-item']} ${styles[`countdown-${eventType}`]}`}>
            <span className={styles['main-countdown__timer-number']}>{format(minutes)}</span>
            <DictionaryValue token='header.countdown.minutes' />
          </div>
          <div className={`${styles['main-countdown__timer-item']} ${styles[`countdown-${eventType}`]}`}>
            <span className={styles['main-countdown__timer-number']}>{format(seconds)}</span>
            <DictionaryValue token='header.countdown.seconds' />
          </div>
        </div>
        <div className={`${styles['main-countdown__timer-text']} ${styles[`countdown-${eventType}`]}`}>
          <span className={styles['main-countdown__timer-text__desktop']}>
            <DictionaryValue token='header.countdown.description.desktop' />
          </span>
          <span className={styles['main-countdown__timer-text__mobile']}>
            <DictionaryValue token='header.countdown.description.mobile' />
          </span>
        </div>
      </div>
    )
  }
}

const MainCountdown = makeCountdown(MainCountdownComponent)
export default MainCountdown
