/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUserUploadedFiles = /* GraphQL */ `query GetUserUploadedFiles($id: ID!) {
  getUserUploadedFiles(id: $id) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserUploadedFilesQueryVariables,
  APITypes.GetUserUploadedFilesQuery
>;
export const listUserUploadedFiless = /* GraphQL */ `query ListUserUploadedFiless(
  $filter: ModelUserUploadedFilesFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserUploadedFiless(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      eventId
      brand
      spend
      paymentMethod
      save
      name
      url
      uploadedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserUploadedFilessQueryVariables,
  APITypes.ListUserUploadedFilessQuery
>;
export const UserUploadedFilesByUser = /* GraphQL */ `query UserUploadedFilesByUser(
  $userId: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserUploadedFilesFilterInput
  $limit: Int
  $nextToken: String
) {
  UserUploadedFilesByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      eventId
      brand
      spend
      paymentMethod
      save
      name
      url
      uploadedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserUploadedFilesByUserQueryVariables,
  APITypes.UserUploadedFilesByUserQuery
>;
export const getUserFrenzWithBenefits = /* GraphQL */ `query GetUserFrenzWithBenefits($id: ID!) {
  getUserFrenzWithBenefits(id: $id) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserFrenzWithBenefitsQueryVariables,
  APITypes.GetUserFrenzWithBenefitsQuery
>;
export const listUserFrenzWithBenefitss = /* GraphQL */ `query ListUserFrenzWithBenefitss(
  $filter: ModelUserFrenzWithBenefitsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserFrenzWithBenefitss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      eventId
      brand
      spend
      paymentMethod
      save
      name
      url
      uploadedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserFrenzWithBenefitssQueryVariables,
  APITypes.ListUserFrenzWithBenefitssQuery
>;
export const UserFrenzWithBenefitsByUser = /* GraphQL */ `query UserFrenzWithBenefitsByUser(
  $userId: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFrenzWithBenefitsFilterInput
  $limit: Int
  $nextToken: String
) {
  UserFrenzWithBenefitsByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      eventId
      brand
      spend
      paymentMethod
      save
      name
      url
      uploadedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserFrenzWithBenefitsByUserQueryVariables,
  APITypes.UserFrenzWithBenefitsByUserQuery
>;
export const getUserFavouriteDeal = /* GraphQL */ `query GetUserFavouriteDeal($id: ID!) {
  getUserFavouriteDeal(id: $id) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserFavouriteDealQueryVariables,
  APITypes.GetUserFavouriteDealQuery
>;
export const listUserFavouriteDeals = /* GraphQL */ `query ListUserFavouriteDeals(
  $filter: ModelUserFavouriteDealFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserFavouriteDeals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      dealId
      brandSlug
      active
      addedAt
      deactivatedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserFavouriteDealsQueryVariables,
  APITypes.ListUserFavouriteDealsQuery
>;
export const UserFavouriteDealsByUser = /* GraphQL */ `query UserFavouriteDealsByUser(
  $userId: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFavouriteDealFilterInput
  $limit: Int
  $nextToken: String
) {
  UserFavouriteDealsByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      userId
      dealId
      brandSlug
      active
      addedAt
      deactivatedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserFavouriteDealsByUserQueryVariables,
  APITypes.UserFavouriteDealsByUserQuery
>;
export const getUserPopupAnswers = /* GraphQL */ `query GetUserPopupAnswers($id: ID!) {
  getUserPopupAnswers(id: $id) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPopupAnswersQueryVariables,
  APITypes.GetUserPopupAnswersQuery
>;
export const listUserPopupAnswerss = /* GraphQL */ `query ListUserPopupAnswerss(
  $filter: ModelUserPopupAnswersFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserPopupAnswerss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      popupId
      username
      mobile
      email
      product
      question
      answer
      answeredAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPopupAnswerssQueryVariables,
  APITypes.ListUserPopupAnswerssQuery
>;
export const getPopup = /* GraphQL */ `query GetPopup($id: ID!) {
  getPopup(id: $id) {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPopupQueryVariables, APITypes.GetPopupQuery>;
export const listPopups = /* GraphQL */ `query ListPopups(
  $filter: ModelPopupFilterInput
  $limit: Int
  $nextToken: String
) {
  listPopups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      title
      product
      price
      rrp
      question
      img
      start
      end
      finish
      usergroups
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPopupsQueryVariables,
  APITypes.ListPopupsQuery
>;
