/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateUserUploadedFiles = /* GraphQL */ `subscription OnCreateUserUploadedFiles($userId: String) {
  onCreateUserUploadedFiles(userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserUploadedFilesSubscriptionVariables,
  APITypes.OnCreateUserUploadedFilesSubscription
>;
export const onUpdateUserUploadedFiles = /* GraphQL */ `subscription OnUpdateUserUploadedFiles($userId: String) {
  onUpdateUserUploadedFiles(userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserUploadedFilesSubscriptionVariables,
  APITypes.OnUpdateUserUploadedFilesSubscription
>;
export const onDeleteUserUploadedFiles = /* GraphQL */ `subscription OnDeleteUserUploadedFiles($userId: String) {
  onDeleteUserUploadedFiles(userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserUploadedFilesSubscriptionVariables,
  APITypes.OnDeleteUserUploadedFilesSubscription
>;
export const onCreateUserFrenzWithBenefits = /* GraphQL */ `subscription OnCreateUserFrenzWithBenefits($userId: String) {
  onCreateUserFrenzWithBenefits(userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserFrenzWithBenefitsSubscriptionVariables,
  APITypes.OnCreateUserFrenzWithBenefitsSubscription
>;
export const onUpdateUserFrenzWithBenefits = /* GraphQL */ `subscription OnUpdateUserFrenzWithBenefits($userId: String) {
  onUpdateUserFrenzWithBenefits(userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserFrenzWithBenefitsSubscriptionVariables,
  APITypes.OnUpdateUserFrenzWithBenefitsSubscription
>;
export const onDeleteUserFrenzWithBenefits = /* GraphQL */ `subscription OnDeleteUserFrenzWithBenefits($userId: String) {
  onDeleteUserFrenzWithBenefits(userId: $userId) {
    id
    userId
    eventId
    brand
    spend
    paymentMethod
    save
    name
    url
    uploadedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserFrenzWithBenefitsSubscriptionVariables,
  APITypes.OnDeleteUserFrenzWithBenefitsSubscription
>;
export const onCreateUserFavouriteDeal = /* GraphQL */ `subscription OnCreateUserFavouriteDeal($userId: String) {
  onCreateUserFavouriteDeal(userId: $userId) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserFavouriteDealSubscriptionVariables,
  APITypes.OnCreateUserFavouriteDealSubscription
>;
export const onUpdateUserFavouriteDeal = /* GraphQL */ `subscription OnUpdateUserFavouriteDeal($userId: String) {
  onUpdateUserFavouriteDeal(userId: $userId) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserFavouriteDealSubscriptionVariables,
  APITypes.OnUpdateUserFavouriteDealSubscription
>;
export const onDeleteUserFavouriteDeal = /* GraphQL */ `subscription OnDeleteUserFavouriteDeal($userId: String) {
  onDeleteUserFavouriteDeal(userId: $userId) {
    id
    type
    userId
    dealId
    brandSlug
    active
    addedAt
    deactivatedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserFavouriteDealSubscriptionVariables,
  APITypes.OnDeleteUserFavouriteDealSubscription
>;
export const onCreateUserPopupAnswers = /* GraphQL */ `subscription OnCreateUserPopupAnswers($username: String) {
  onCreateUserPopupAnswers(username: $username) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserPopupAnswersSubscriptionVariables,
  APITypes.OnCreateUserPopupAnswersSubscription
>;
export const onUpdateUserPopupAnswers = /* GraphQL */ `subscription OnUpdateUserPopupAnswers($username: String) {
  onUpdateUserPopupAnswers(username: $username) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserPopupAnswersSubscriptionVariables,
  APITypes.OnUpdateUserPopupAnswersSubscription
>;
export const onDeleteUserPopupAnswers = /* GraphQL */ `subscription OnDeleteUserPopupAnswers($username: String) {
  onDeleteUserPopupAnswers(username: $username) {
    id
    popupId
    username
    mobile
    email
    product
    question
    answer
    answeredAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserPopupAnswersSubscriptionVariables,
  APITypes.OnDeleteUserPopupAnswersSubscription
>;
export const onCreatePopup = /* GraphQL */ `subscription OnCreatePopup {
  onCreatePopup {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePopupSubscriptionVariables,
  APITypes.OnCreatePopupSubscription
>;
export const onUpdatePopup = /* GraphQL */ `subscription OnUpdatePopup {
  onUpdatePopup {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePopupSubscriptionVariables,
  APITypes.OnUpdatePopupSubscription
>;
export const onDeletePopup = /* GraphQL */ `subscription OnDeletePopup {
  onDeletePopup {
    id
    eventId
    title
    product
    price
    rrp
    question
    img
    start
    end
    finish
    usergroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePopupSubscriptionVariables,
  APITypes.OnDeletePopupSubscription
>;
