import * as React from 'react'
// import { CfButton } from '../../../../../components/cf-package'
import { Link } from '../../../../../components'
import { CfDealItem } from '../../../../../models/cfdeal'
import { getImgUrl } from '../../../../../utilities/deal.helper'
import { getWebpImage } from '../../../../../utilities'

type Props = {
  className?: string
  id?: string
  data: CfDealItem
  openTerm?: () => void
}

class DealCardSlide extends React.Component<Props> {
  render() {
    const { className = '', id, data, openTerm } = this.props
    const { brandLogoId, dealImageId, smImageId, description, title, trackingUrl, terms } = data

    return (
      <div
        id={id}
        className={`cf-deal-card ${className}`}
      >
        <div className='row cf-flex flex-row'>
          <div className='card-ct cf-flex flex-row'>
            <div className='inner cf-flex align-center'>
              <div>
                <figure className='logo-frame'>
                  <Link
                    to={trackingUrl}
                    target='_blank'
                  >
                    <img
                      src={getWebpImage(getImgUrl(brandLogoId))}
                      alt='logo feature deal'
                    />
                  </Link>
                </figure>
                <h4 className='card-title font-barlow-bold'>
                  <Link
                    to={trackingUrl}
                    target='_blank'
                    className='title-link'
                  >
                    {title}
                  </Link>
                </h4>
                <div className='desc'>{description}</div>
                {terms && (
                  <span
                    onClick={openTerm ? () => openTerm() : undefined}
                    className='link-blk'
                  >
                    Terms apply
                  </span>
                )}
                <div className='cta-blk'>
                  <Link
                    to={trackingUrl}
                    target='_blank'
                    className='button button--type-blue button--action-callback button--style-rounded button--display-inline button--size-large'
                  >
                    Shop Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='card-img'>
            <figure>
              <Link
                to={trackingUrl}
                target='_blank'
              >
                <img
                  src={getWebpImage(getImgUrl(dealImageId))}
                  alt='feature deal'
                  className='img-desktop'
                />
                {smImageId && (
                  <img
                    src={getWebpImage(getImgUrl(smImageId))}
                    alt='feature deal'
                    className='img-mobile'
                  />
                )}
              </Link>
            </figure>
          </div>
        </div>
      </div>
    )
  }
}

export default DealCardSlide
