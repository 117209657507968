import bind from 'bind-decorator'
import React from 'react'
import { history } from '../../../../src/history'
import { DictionaryValue } from '../../../components/dictionary-value'
import Category from '../../../models/category'
import DealPlacement from '../../../models/dealPlacement'
import EventType from '../../../models/eventType'
import FeaturedBrand from '../../../models/featuredBrand'
import { enableScroll, getWebpImage } from '../../../utilities'
import CardWithData from '../../deal/CardWithData'
import './MobileNav.scss'
import SecondNavIcon from './SecondNavIcon'

export interface FieldProps {
  resetPageHeight?: () => void | undefined
  isMobileNavMenuOpen: boolean
  categories: Category[]
  categoryFeaturedBrands: FeaturedBrand | undefined
  isEventRunning: boolean
  eventType?: EventType | null
  isLoggedIn: boolean
}

export interface MethodProps {
  openMobileNavMenu: () => void
  closeMobileNavMenu: () => void
}

type Props = FieldProps & MethodProps

type State = {
  isMainCategoryActive: boolean
  mainCategorySelectedIndex: number | null
  isSubCategoryActive: boolean
}

class MobileNav extends React.Component<Props, State> {
  readonly state: State = {
    isMainCategoryActive: false,
    mainCategorySelectedIndex: null,
    isSubCategoryActive: false,
  }

  componentDidMount() {
    if (this.props.resetPageHeight) {
      this.props.resetPageHeight()
    }
  }

  @bind
  closeMobileNavMenu() {
    const { closeMobileNavMenu } = this.props
    closeMobileNavMenu()
    enableScroll()
  }

  @bind
  handleBrandLink() {
    this.closeMobileNavMenu()
    history.push('/brands')
  }

  @bind
  handleExclusiveDealLink() {
    this.closeMobileNavMenu()
    history.push('/cf-exclusives')
  }

  @bind
  handleBestOfFrenzyLink() {
    this.closeMobileNavMenu()
    history.push('/best-of-frenzy')
  }

  @bind
  handleSignUpLink() {
    this.closeMobileNavMenu()
    history.push('/account?join')
  }

  @bind
  handleLoginButton() {
    this.closeMobileNavMenu()
    history.push('/account?login')
  }

  @bind
  toggleMainCategoryMenu() {
    this.setState((prevState: State): State => {
      if (prevState.isMainCategoryActive) {
        return {
          ...prevState,
          isMainCategoryActive: false,
        }
      }
      return {
        ...prevState,
        isMainCategoryActive: true,
      }
    })
  }

  @bind
  handleMainCategory(event: React.SyntheticEvent<HTMLLIElement>) {
    if (event.currentTarget.dataset.index) {
      const indexSelected = parseInt(event.currentTarget.dataset.index, 10)
      this.setState((prevState: State): State => {
        return {
          ...prevState,
          isMainCategoryActive: false,
          mainCategorySelectedIndex: indexSelected,
          isSubCategoryActive: true,
        }
      })

      if (this.props.categories[indexSelected].subCategories?.length === 0) {
        this.closeMobileNavMenu()
        history.push(`/${this.props.categories[indexSelected].slug}`)
      }
    }
  }

  @bind
  toggleSubCategoryMenu() {
    this.setState((prevState: State): State => {
      if (prevState.isSubCategoryActive) {
        return {
          ...prevState,
          isMainCategoryActive: true,
          isSubCategoryActive: false,
          mainCategorySelectedIndex: null,
        }
      }
      return {
        ...prevState,
        isSubCategoryActive: true,
        isMainCategoryActive: false,
      }
    })
  }

  @bind
  handleSubCategory(event: React.SyntheticEvent<HTMLLIElement>) {
    if (event.currentTarget.dataset.slug) {
      this.closeMobileNavMenu()
      history.push(event.currentTarget.dataset.slug)
    }
  }

  @bind
  handleFeaturedBrands(event: React.SyntheticEvent<HTMLDivElement>) {
    if (event.currentTarget.dataset.slug) {
      this.closeMobileNavMenu()
      history.push(event.currentTarget.dataset.slug)
    }
  }

  render() {
    const { categories, isEventRunning, eventType, categoryFeaturedBrands } = this.props
    let subCategories: Category[] | undefined = []
    let featuredDealSlug: DealPlacement[] | undefined = []
    let featuredBrands: FeaturedBrand | undefined
    let mainCategorySelectedSlug: string | undefined

    if (this.state.mainCategorySelectedIndex !== null) {
      subCategories = categories[this.state.mainCategorySelectedIndex].subCategories
      featuredDealSlug = categories[this.state.mainCategorySelectedIndex].featuredDealSlug
      featuredBrands = categories[this.state.mainCategorySelectedIndex].featuredBrands
      mainCategorySelectedSlug = categories[this.state.mainCategorySelectedIndex].slug
    }

    return (
      <div className='mobile-nav__body'>
        <div className='mobile-nav__header-section'>
          <div className='mobile-nav__header-container'>
            <div className='mobile-nav__header-heading'>Menu</div>
            <div
              className='mobile-nav__header-icon'
              onClick={this.closeMobileNavMenu}
            >
              <svg
                width={14}
                height={14}
                viewBox='0 0 14 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 13L13 1M1 1L13 13'
                  stroke='black'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          className='mobile-nav__menu-section'
          data-scroll-lock-scrollable={true}
        >
          <div className='mobile-nav__menu-container'>
            <div
              className='mobile-nav__menu-main'
              style={{
                display: this.state.isMainCategoryActive || this.state.isSubCategoryActive ? 'none' : 'block',
              }}
            >
              <div className='mobile-nav__menu-main-list'>
                {isEventRunning && (
                  <React.Fragment>
                    {categories.length > 0 && (
                      <div
                        className='list-item'
                        onClick={this.toggleMainCategoryMenu}
                      >
                        <div className='item-icon'>
                          <SecondNavIcon eventType={this.props.eventType} />
                        </div>
                        <div className='item-text'>Shop by Category</div>
                      </div>
                    )}
                    <div
                      className='list-item'
                      onClick={this.handleBrandLink}
                    >
                      <div className='item-icon'>
                        <SecondNavIcon eventType={this.props.eventType} />
                      </div>
                      <div className='item-text'>Shop by Brand</div>
                    </div>
                    {/* <div
                      className='list-item'
                      onClick={this.handleExclusiveDealLink}
                    >
                      <div className='item-icon'>
                        <SecondNavIcon eventType={this.props.eventType} />
                      </div>
                      <div className='item-text'>Exclusive Deals</div>
                    </div> */}
                    <div
                      className='list-item'
                      onClick={this.handleBestOfFrenzyLink}
                    >
                      <div className='item-icon'>
                        <SecondNavIcon eventType={this.props.eventType} />
                      </div>
                      <div className='item-text'>Best of Frenzy</div>
                    </div>
                  </React.Fragment>
                )}
                <a
                  href='https://faq.clickfrenzy.com.au/support/home'
                  className='list-item-link'
                >
                  <div className='list-item'>
                    <div className='item-text'>FAQs</div>
                  </div>
                </a>
              </div>
              {!this.props.isLoggedIn && (
                <div className='mobile-nav__menu-main-action'>
                  <div className='signup-cta'>
                    <div className='text'>Not a member?</div>
                    <div
                      className='link'
                      onClick={this.handleSignUpLink}
                    >
                      Sign up today
                    </div>
                  </div>
                  <div
                    className={`login-cta mobile-nav-${eventType}`}
                    onClick={this.handleLoginButton}
                  >
                    <div className='login-cta-icon'>
                      <div className='icon-top' />
                      <div className='icon-bottom' />
                    </div>
                    <div className='login-cta-text'>
                      <DictionaryValue token='header.nav.links.login' />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className='mobile-nav__menu-category-main'
              style={{ display: this.state.isMainCategoryActive ? 'block' : 'none' }}
            >
              <div
                className='back-button'
                onClick={this.toggleMainCategoryMenu}
              >
                <div className='icon'>
                  <svg
                    width={10}
                    height={17}
                    viewBox='0 0 10 17'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M8.59961 15.5L1.59961 8.5L8.59961 1.5'
                      stroke='black'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <div className='text'>Back</div>
              </div>
              <ul className='list'>
                {categories.map((item, index) => {
                  return (
                    <li
                      className='list-item'
                      key={item.slug}
                      data-index={index}
                      onClick={this.handleMainCategory}
                    >
                      {item.name}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div
              className='mobile-nav__menu-category-sub'
              style={{
                display: this.state.isSubCategoryActive || this.state.isMainCategoryActive ? 'block' : 'none',
                paddingBottom: '150px',
              }}
            >
              {!this.state.isMainCategoryActive && (
                <div
                  className='back-button'
                  onClick={this.toggleSubCategoryMenu}
                >
                  <div className='icon'>
                    <svg
                      width={10}
                      height={17}
                      viewBox='0 0 10 17'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M8.59961 15.5L1.59961 8.5L8.59961 1.5'
                        stroke='black'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <div className='text'>Back</div>
                </div>
              )}
              <ul className='list'>
                {!this.state.isMainCategoryActive && (
                  <li
                    className='list-item'
                    data-slug={`/${mainCategorySelectedSlug}`}
                    onClick={this.handleSubCategory}
                  >
                    All
                  </li>
                )}
                {subCategories &&
                  subCategories.map(item => {
                    return (
                      <li
                        key={item.slug}
                        className='list-item'
                        data-slug={`/${mainCategorySelectedSlug}/${item.slug}`}
                        onClick={this.handleSubCategory}
                      >
                        {item.name}
                      </li>
                    )
                  })}
              </ul>
              {(this.state.mainCategorySelectedIndex === null
                ? categoryFeaturedBrands && categoryFeaturedBrands.brands.length > 0
                : featuredBrands && featuredBrands.brands.length > 0) && (
                <div className='mobile-nav__featured-brands'>
                  <div className='title'>
                    <DictionaryValue token='modal.column.featured-brands' />
                  </div>
                  <div className='row'>
                    {(this.state.mainCategorySelectedIndex === null
                      ? categoryFeaturedBrands
                      : featuredBrands
                    )?.brands.map(item => (
                      <div
                        className='col-xs-6 col-sm-4 mobile-nav__brand-container'
                        key={item.slug}
                        data-slug={`/brands/${item.slug}`}
                        onClick={this.handleFeaturedBrands}
                      >
                        <div className='mobile-nav__brand-logo'>
                          <img
                            data-blink-src={getWebpImage(item.squarelogo ? item.squarelogo : item.logo)}
                            alt={item.name}
                            className='mobile-nav__brand-image'
                          />
                        </div>
                        <div className='mobile-nav__brand-title'>{item.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {featuredDealSlug && featuredDealSlug.length > 0 && (
                <div className='mobile-nav__featured-deal'>
                  <div className='title'>
                    <DictionaryValue token='modal.column.featured-deal' />
                  </div>
                  <div className='mobile-nav__deal-container'>
                    <CardWithData
                      slug={featuredDealSlug.sort((a, b) => Number(a.position) - Number(b.position))[0].slug}
                      showHover={false}
                      location='home'
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MobileNav
